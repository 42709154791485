

.button {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  background-color: #8080800A;
  float: right;
  cursor: pointer;
  gap: 8px;
  border: 1px solid rgba(128, 128, 128, 0.2);
}


.button img {
  width: 16px;
  height: 16px;
}


.buttonText{
  margin: 0;
  padding: 0;
}


.headerButtonsContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  float: inline-end;
  gap: 12px;
  margin-top: 20px;
}

.statusContainer {
  width: calc(100% - 65px);
  height: auto;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgb(128 128 128 / 16%);
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
}

.horizLine {
  width: 100%;
  border: 1px solid #EEEEEE;
}


.statusRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.eventDetailsContainer {
  width: calc(100% - 40px);
  height: 33px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgb(128 128 128 / 16%);
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 10px;
  padding: 4px;
}

.eventDetailsContainer span {
  height: 33px;
  width: auto;
  background-color: transparent;
  flex: 1;
  color: #484848;
  font-family:  SFProRounded;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: right;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}

.eventDetailsContainer .activeTab {
  background-color: #1080FF1A;
  color: #1080FF;
}

.pickup_dropOff_collapseRowContainer {
  width: 480px;
  height: 74px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pickup_dropOff_collapseRow {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}


.pickup_dropOff_collapseSubRow {
  width: calc(calc(100% - 20px) / 3);
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;
}


.noteContainer {
  width: calc(100% - 65px);
  height: auto;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgb(128 128 128 / 16%);
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  grid-gap: 8px;
  gap: 8px;
  padding: 12px 16px;
}

.noteContainer img {
  width: 20px;
}
.noteContainer span {
  font-family: "SFProRounded";
  font-size: 14px;
  font-weight: 300;
  line-height: 16.71px;
  text-align: left;
  color: #242424;  
  text-wrap: wrap;
}

.buttonMobile {
  padding: 0px 8px 0 8px;

}

@media(max-width:599px) {
  .statusRow {
    flex-wrap: wrap;
  }

  .noteContainer {
    width: calc(100% - 64px);
  }

  .pickup_dropOff_collapseRowContainer {
    width: calc(100vw - 64px);
  }
}




.extraDetail {
  height: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(128, 128, 128, 0.16);
  background-color: #ffffff;
  margin-right: 16px;
  float: left;
  margin-top: 12px;

}

.extraDetail img {
  width: 18px;
  height: 20px;
  margin-top: 5px;
  float: left;
  margin-right: 8px;
}

.extraDetail .img {
  width: 18px;
  height: 20px;
  margin-top: 5px;
  float: left;
  margin-right: 8px;
}

.extraDetail .value {
  height: 14px;
  float: left;
  /* height: 22px; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
  /* margin-left: 16px; */
  margin-top: 1px;
  float: left;
  direction: ltr;
  text-align: left;
}

.extraDetail .key {
  height: 14px;
  float: left;
  /* height: 22px; */
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  margin-left: 0;
  margin-top: 8px;
  float: left;
  direction: ltr;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  grid-gap: 2px;
  gap: 2px;
}


.rowButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 15px;
}