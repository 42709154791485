.PrimaryBg {
   background-color: #1080FF;
}

.PrimaryHoverBg {
   background-color: #006CE7;
}

.PrimaryDarkBg {
   background-color: #004CC4;
}

.PrimaryActiveBg {
   background-color: #1080FF3D;
}


.UltraLightBg {
   background-color: #1080FF14;
}

.TextDarkBg {
   background-color: #242424;
}

.TextNormalBg {
   background-color: #484848;
}

.TextLightBg {
   background-color: #808080;
}

.GrayDarkBg {
   background-color: #979797;
}

.GrayNormalBg {
   background-color: #D5D5D5;
}

.GrayLightBg {
   background-color: #8080800F;
}

.GrayHoverBg {
   background-color: #8080801F;
}

.GrayUltraLightBg {
   background-color: #8080800F;
}

.BlueGrayBg {
   background-color: #F4F4F7;
}

.BlackBg {
   background-color: #000000;
}

.WhiteBg {
   background-color: #CED4DA;
}

.WhiteText  {
   color: #ffffff;
}

.RedBg {
   background-color: #D01400;
}

.RedlightBg {
   background-color: #EC1700;
}

.RedUltraLightBg {
   background-color: #D0140014;
}

.GreenBg {
   background-color: #009B62;
}

.GreenUltraLightBg {
   background-color: #009B6214;
}


.PrimaryText {
   color: #1080FF;
}


.PrimaryHoverText {
   color: #006CE7;
}

.PrimaryDarkText {
   color: #004CC4;
}

.PrimaryActiveText {
   color: #1080FF3D;
}


.UltraLightText {
   color: #1080FF14;
}

.TextDarkText {
   color: #242424;
}

.TextNormalText {
   color: #484848;
}

.TextLightText {
   color: #808080;
}

.GrayDarkText {
   color: #979797;
}

.GrayNormalText {
   color: #D5D5D5;
}

.GrayLightText {
   color: #8080800F;
}

.GrayHoverText {
   color: #8080801F;
}


.GrayUltraLightBg {
   color: #8080800F;
}

.BlueGrayText {
   color: #F4F4F7;
}

.BlackText {
   color: #000000;
}

.RedText {
   color: #D01400;
}

.RedlightText {
   color: #EC1700;
}

.RedUltraLightText {
   color: #D0140014;
}

.GreenText {
   color: #009B62;
}

.GreenUltraLightText {
   color: #009B6214;
}



.AssignedBg {
   background-color: #25b7ff;
}

.AssignedText {
   color: #25b7ff;
}

.PendingBg {
   background-color: #e025ff;
}

.PendingText {
   color: #e025ff;
}

.StartedBg {
   background-color: #004cc4;
}

.StartedText {
   color: #004cc4;
}

.ArrivedBg {
   background-color: #004cc4;
}

.ArrivedText {
   color: #004cc4;
}

.PickedUpBg {
   background-color: #004cc4;
}

.PickedUpText {
   color: #004cc4;
}

.DeliveringBg {
   background-color: #004cc4;
}

.DeliveringText {
   color: #004cc4;
}

.DoneBg {
   background-color: #009b62;
}

.DoneText {
   color: #009b62;
}

.SupportCanceledBg {
   background-color: #484848;
}

.SupportCanceledText {
   color: #484848;
}

.DriverCanceledBg {
   background-color: #484848;
}

.DriverCanceledText {
   color: #484848;
}

.CustomerCanceledBg {
   background-color: #484848;
}

.CustomerCanceledText {
   color: #484848;
}

.CollectingBg {
   background-color: #25b7ff;
}

.CollectingText {
   color: #25b7ff;
}

.TransitBg {
   background-color: #dcb900;
}

.TransitText {
   color: #dcb900;
}

.UnassignedBg {
   background-color: #e99514;
}

.UnassignedText {
   color: #e99514;
}