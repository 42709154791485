.modal {
    position: fixed;
    /* Stay in place */
    z-index: 50;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Black w/ opacity */
}


.Small {
    width: 320px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    background: #FFF;

    /* Shadow 1 */
    box-shadow: 0px 1px 6px 0px rgba(128, 128, 128, 0.16);
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index:52 ;
}

.SmallTitle{
    color: var(--neutrals-text-dark, #242424);
    /* Body/Middle/Bold */
    padding: 0;
    margin: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
}
.SmallDescription{
    color: var(--neutrals-text-normal, #484848);
    /* Body/Middle/Bold */
    padding: 0;
    margin: 0;
    margin-top: 6px;
    display: block;
    width: 100%;
}

.buttonsContainer{
    display: flex;
    flex-direction: row;
    gap:12px;
    margin: 0;
    margin-top: 20px;
    float: inline-start;
    width: 100%;

}



.Large {
    border-radius: 8px;
    background: #F4F4F7;
    box-shadow: 0px 1px 6px 0px rgba(128, 128, 128, 0.16);
    display: flex;
    width: 520px;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    z-index:52 ;
}

.LargeTitle{
    color: var(--neutrals-text-dark, #242424);
    /* Body/Middle/Bold */
    padding: 0;
    margin: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
}
.LargeSubTitle{
    color: var(--neutrals-text-normal, #484848);
    /* Body/Middle/Bold */
    padding: 0;
    margin: 0;
    margin-top: 6px;
    display: block;
    width: 100%;
}

.largeHeader{
    border-radius: 8px 8px 0px 0px;
    background: #FFF;
    width: calc(520px - 32px);
    height: auto;
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 12px;
}

.largeHeaderTitles{
    height: 32px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: auto;
    align-items: start;
    justify-content: center;
    margin-inline-end: auto;
}

.largeBody {
    width: calc(520px - 32px);
    height: auto;
    padding: 16px;
    background: #FFF;
}


.largeFooter{
    width: calc(520px - 32px);
    height: 52px;
    padding: 0px 16px;
    background: #FFF;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: end;
}


.largeFooterddwdwdwdwdw span {
    color: white;
}

.backDrop{
    position: fixed;
    /* Stay in place */
    z-index: 51;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
}


@media(max-width:690px) {

    .Large {
        width: calc(100vw);
    }
    .largeHeader {
        width: calc(100vw -  32px);
    }

    .largeBody {
        width: calc(100vw -  32px);

    }
    
    
    .largeFooter{
        width: calc(100vw -  32px);
    }
}