.Heading_Small_Bold {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: #242424;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;
}

.Body_Middle_Bold {
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Small_Bold {
    color: #242424;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Subtitle_Tiny_Regular {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}



.Body1Bold {
    color:#212529;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Middle_Medium {
    color:  #212529;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Middle_Regular {
    color:  #212529;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Small_Medium {
    color:  #212529;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Small_Regular {
    color:  #212529;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}
.Body_Small_Medium {
    color:  #212529;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Tiny_Bold {
    color:#212529;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Tiny_Medium {
    color:  #212529;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}

.Body_Tiny_Regular {
    color:  #212529;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    /* font-family: "sf_pro"; */
    text-align: start;
    direction: inherit;
    float: inline-start;

}