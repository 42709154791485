.size10 {
    width: 10px;
    height: 10px;
}
.size12 {
    width: 12px;
    height: 12px;
}
.size12 {
    width: 14px;
    height: 14px;
}

.size16 {
    width: 16px;
    height: 16px;
}

.size24 {
    width: 24px;
    height: 24px;
}

.size32 {
    width: 32px;
    height: 32px;
}

.size56 {
    width: 56px;
    height: 56px;
}