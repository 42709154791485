.container {
  padding: 12px 16px;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: start;
  align-items: center;
  width: auto !important;
  min-width: auto !important;
  max-width: fit-content !important;
  cursor: default;
  transition: all ease-in-out 300ms;
}

.first{
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: white;
}

.queued {
  color :#1080FF;
  padding: 4px 8px;
  background-color: #1080FF14;
  border-radius: 8px;
}

.processing {
  color :#1080FF;
  padding: 4px 8px;
  background-color: #1080FF14;
  border-radius: 8px;
}

.done {
  color :#009B62;
  padding: 4px 8px;
  background-color: #009B6214;
  border-radius: 8px;
}

.divider {
  width: 1.5px;
  height: 32px;
  background-color: white;
}

.close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}