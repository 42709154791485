.EditStatusSummary {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  position: fixed;
  z-index: 5;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.header {
  width: calc(100% - 32px);
  height: 32px;
  border-bottom: 1px solid #F4F4F7;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
}

.largeHeaderTitles{
  height: 32px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: auto;
  align-items: start;
  justify-content: center;
}

.SmallDescription{
  color: var(--neutrals-text-normal, #484848);
  /* Body/Middle/Bold */
  padding: 0;
  margin: 0;
  margin-top: 6px;
  display: block;
  width: 100%;
}


.tabsContainer {
  width: calc(100% - 32px);
  height: 52px;
  border-bottom: 1px solid #F4F4F7;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0px 16px;
  gap: 0px;
}

.tab {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0px 16px;
  gap: 6px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.tabBadge {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  background: #484848;
  color: white;
  padding: 1px 3px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;

}

.bottomTab{
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0px;
  border-radius: 4px 4px 0 0;
  background-color: transparent;
  height: 6px;
  transition: all .2s ease-in-out;

}


.activeTab span {
  color: #1080FF !important;
}

.activeTab .tabBadge {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  background: #1080FF;
  color: white !important;
  padding: 1px 3px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.activeTab .bottomTab{
  background-color: #1080FF;
}

.list {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.fistcol {
  width: auto;
}

.secocol {
  flex: 1;
  
}


.headerCol {
  padding: 0px;
  background-color: #8080800F;
  height: 42px;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.headerCol span{
  width: 100%;
  margin: 0px;
  padding: 1px 12px;
  border-inline-start: 1px solid rgb(230, 230, 230);

}

.dataCol {
  padding: 0px 12px;
  background-color: white;
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.dataCol img {
  width: 16px;
  height: 16px;
  padding: 6px;
  border-radius: 8px;
  background-color: #8080800F;
  cursor: pointer;
}