.icNewOrder {
  width: 11px;
  height: 11px;
  padding: 10.5px 9.5px 11.5px;
  cursor: pointer;
  margin: 0;
  border-end-start-radius: 8px;
  border-start-start-radius: 8px;
  float: var(--main-defaultFloat);
}

.icMenu {
  width: 7px;
  padding: 13px 8.5px;
  margin: 0;
  cursor: pointer;
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
  float: var(--main-defaultFloat);
  border-inline-start-color: white;
  border-inline-start-width: .5px;
  border-inline-start-style: solid;

}

.container {
  width: 57px;
  height: 32px;
  border-radius: 8px;
  direction: var(--main-direction);
  float: inherit;
  margin-top: 11px;
  margin-inline-start: 16px;
  background: var(--Linear, linear-gradient(180deg, #25B7FF 0%, #1080FF 100%));
}

.MenuRowContainer {
  height: 17px;
  width: auto;
  box-shadow: 0 1px 6px 0 rgba(128, 128, 128, 0.16);
  border-radius: 12px;
  padding: 12px 0;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding : 16px;
}

.MenuRowIcon {
  width: 18px;
  height: 15.6px;
  float: var(--main-defaultFloat);
  margin-inline-start: 0;
}

.MenuRowTitle {
  height: 17px;
  float: var(--main-defaultFloat);
  margin-inline-start: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  text-decoration: none;
}







.button {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  background-color: rgba(128, 128, 128, 0.06);
  float: right;
  cursor: pointer;
  margin-top: 13px;
  gap: 8px;
}


.button img {
  width: 16px;
  height: 16px;
}


.buttonText{
  margin: 0;
  padding: 0;
}




.buttonx {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  float: right;
  cursor: pointer;
  margin-top: 13px;
  gap: 8px;
  background: linear-gradient(180deg, #25B7FF 0%, #1080FF 100%);
  margin-inline-start: 12px;
}


.buttonx img {
  width: 16px;
  height: 16px;
}


.buttonxText{
  margin: 0;
  padding: 0;
}