.orderTypeContainer {
  width: auto;
  height: 33px;
  border-radius: 8px;
  background-color: #8080800F;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px 4px;
  float: inherit;
  margin-top: 11px;
  margin-inline-start: 20px;
}

.orderType {
  height: 25px;
  padding: 0px 16px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}


.orderTypeActive {
  height: 25px;
  padding: 0px 16px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}


.button {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  background-color: rgba(128, 128, 128, 0.06);
  float: right;
  cursor: pointer;
  margin-top: 13px;
  gap: 8px;
}


.button img {
  width: 16px;
  height: 16px;
}


.buttonText{
  margin: 0;
  padding: 0;
}




.buttonx {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  float: right;
  cursor: pointer;
  margin-top: 13px;
  gap: 8px;
  background: linear-gradient(180deg, #25B7FF 0%, #1080FF 100%);
  margin-inline-start: 12px;
}


.buttonx img {
  width: 16px;
  height: 16px;
}


.buttonxText{
  margin: 0;
  padding: 0;
}