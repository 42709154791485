.ServiceNewOrderContainer {
  width: 100%;
  float: inline-start;
  min-height: 54px;
  background: #FFF;
  height: auto;
  margin-bottom: 6px;
}

.ServiceNewOrderFirstRow {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: calc(100% - 32px);
  margin-top: 10px;
  margin-inline-start: 16px;
  height: 32px;
  align-items: center;
}


.ServiceNewOrderSecondRow {
  border-top: 1px solid #F4F4F7;;
  display: flex;
  flex-direction: row;
  gap: 11px;
  width: calc(100% - 32px);
  margin-top: 10px;
  margin-inline-start: 16px;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.service {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.DropDownTextStyle {
  max-width: 400px;
    text-overflow: ellipsis !important;
    /* max-width: 135px !important; */
    overflow: hidden !important;
    text-wrap: nowrap !important;
}


.divider {
  width: 1.5px;
  height: 32px;
  background-color: rgba(244, 244, 247, 1);
}







.itemsConatiner {

  width: 100%;

  border-radius: 8px;
  display: flex;
  padding:0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 10;
  max-height: 222px;
  overflow-y: auto;
  min-width: calc(100% - 32px);
}

.itemsConatiner div {
  padding: 8px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--neutrals-text-normal, #484848);
  text-wrap: nowrap;
}

.itemsConatiner div img{
  width: 16px;
  height: 16px;

}