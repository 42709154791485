.container {
    display: inline-flex;
    height: 16px;
    padding: 3px 4px;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 100px;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));

    color: var(--neutrals-text-normal, #484848);
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.container span{
    padding: 1px 8px;
    border-radius: 9px;
    transition: all 400ms ease-in-out;
    user-select: none;
}


.selectedTab{
    background: #FFF;
    border-radius: 9px;
    color: var(--Secondaryonlight, #1080FF);
    font-weight: 700;
}


.topMenu{
    background-color: #8080800F;
    height: 25px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 4px;
    border-radius: 8px;
}

.topMenu span{
    height: 23px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px auto;
    border-radius: 6px;
    line-height: 1.1;
}