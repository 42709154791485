.th {
  height: 42px;
  padding: 0;
  background-color: #f8f8f8 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.thContainer {
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-inline-start: 14px;
  grid-gap: 8px;
  gap: 8px;
  border-right: 1px solid #e6e6e6;

}


.thspanContainer{
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}
/* .checkbox {
  width: 16.7px;
  height: 16.7px;
  border-radius: 5px;
  border: 1px solid red;
  background-color: white;
  -moz-appearance:none;
  -webkit-appearance:none;
  -o-appearance:none;
}
 */


.checkbox
{
	-webkit-appearance:none;
  width: 16.7px;
  height: 16.7px;
    display: inline-block;
    background: #FFFFFF;
	border: 1px solid rgba(220,220,225,1);
  border-radius: 5px;
}
.checkbox:after {
  content: "";
  display: inline-block;
  position: relative;
  top: -3px;
  left: 4px;
  width: 3px;
  height: 5px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: rotate(45deg);
}

.checkbox:checked   {
    background: #327DFF;
    outline: none;
    border: 1px solid rgba(50,125,255,1);
}
.checkbox:focus,.checkbox:active {
	outline: none;
}

.checkbox:hover {
   border: 1px solid rgba(50,125,255,1);
}

.tr {
  height: 42px;
  padding: 14px 0px;


}

.td {
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left !important;
}


.Draft {
  border-radius: 8px !important;
 margin-inline-start: 12px;
 background-color: rgba(72, 72, 72, 0.08);
 color: #484848 !important ;
}
.Draft span{
  color: #484848 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Confirmed {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(233, 149, 20, 0.08);
  color: #e99514 !important ;
 }
 .Confirmed span{
  color: #e99514 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }

 .PickupRouted {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(16, 128, 255, 0.08)  ;
  color: #1080ff !important ;
 }
 .PickupRouted span{
  color: #1080ff !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .PickedUp {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(0, 76, 196, 0.08);
  color: #004cc4 !important ;
 }
 .PickedUp span{
  color: #004cc4 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .AtWarehouse {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(233, 149, 20, 0.08);
  color: #e99514 !important ;
 }
 .AtWarehouse span{
  color: #e99514 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .DeliveryRouted {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(16, 128, 255, 0.08)  ;
  color: #1080ff !important ;
 }
 .DeliveryRouted span{
  color: #1080ff !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }

 .ReadyForDelivery {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(0, 76, 196, 0.08);
  color: #004cc4 !important ;
 }
 .ReadyForDelivery span{
  color: #004cc4 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .ReadyForDelivery {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(0, 76, 196, 0.08);
  color: #004cc4 !important ;
 }
 .ReadyForDelivery span{
  color: #004cc4 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .Delivered {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(0, 155, 98, 0.08)  ;
  color: #009b62 !important ;
 }
 .Delivered span{
  color: #009b62 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .PickupFailed {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(208, 20, 0, 0.08)  ;
  color: #d01400 !important ;
 }
 .PickupFailed span{
  color: #d01400 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }

 .NotDelivered {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(208, 20, 0, 0.08)  ;
  color: #d01400 !important ;
 }
 .NotDelivered span{
  color: #d01400 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .OutForDelivery {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(0, 76, 196, 0.08);
  color: #004cc4 !important ;
 }
 .OutForDelivery span{
  color: #004cc4 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }

 .CustomerCanceled {
  border-radius: 8px !important;
  margin-inline-start: 12px;
  background-color: rgba(72, 72, 72, 0.08);
  color: #484848 !important ;
 }

 .CustomerCanceled span{
  color: #484848 !important ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }


 .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }


 .paginationContainer{
  width: calc(100% - 32px);
  height: 42px;
  margin: 1px 0 0 1px;
  border-top: solid 1px #f4f4f7  ;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 0 16px;
  gap: 17px;
  position: relative;
 }

 .paginationButtonContainer{
  width: 164px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  left: calc(50% - 82px);
 }

 .paginationButton {
  width: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  height: 100%;
 }

 .paginationButton img {
  width: 16px;
  height: 16px;
 }

 .DisabledpaginationButton {
  opacity: .4;
  cursor: not-allowed;
 }


 .dialog{
  width: 243px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
 }


 

.button {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  float: right;
  cursor: pointer;
  gap: 8px;
  border: 1px solid rgba(128, 128, 128, 0.2);
}


.button img {
  width: 16px;
  height: 16px;
}


.buttonText{
  margin: 0;
  padding: 0;
}




.buttonx {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  float: right;
  cursor: pointer;
  gap: 8px;
  background: linear-gradient(180deg, #25B7FF 0%, #1080FF 100%);
}


.buttonx img {
  width: 16px;
  height: 16px;
}


.buttonxText{
  margin: 0;
  padding: 0;
}