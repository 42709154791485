.TableFilter {
  width: calc(100% - 30px);
  padding: 0px 15px;
  height: 46.5px;
  /* border-top: 1px solid #8080800F; */
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.Clear {
  color: #1080FF;
  height: 17px;
  cursor: pointer;
}