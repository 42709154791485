.Primary {
    color: var(--primary-text-color,#FFF);
    text-align: center;
    /* font-family: "sf_pro"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: var(--primary-primary, #1080FF);
    display: inline-flex;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    user-select: none;
    height: 14px;
    max-height: 14px;



}

.Primary span {
    text-overflow: ellipsis !important;
    max-width: 135px !important;
    overflow: hidden !important;
    text-wrap: nowrap !important;

}

.Primary:hover {
    background: var(--primary-hover, #006CE7) !important;
}


.Primary:active {
    background: var(--primary-dark, #004CC4) !important;
}

.Primarydisabled {
    background: var(--primary-primary, #1080FF);
    opacity: 0.4;
    cursor: not-allowed;
}

.PrimaryisLoading {
    cursor: not-allowed;
}




.Secondary {
    color: var(--neutrals-text-normal, #484848);
    text-align: center;
    /* font-family: "sf_pro"; */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));
    display: inline-flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    user-select: none;
    max-height: 14px;

}

.Secondary span {
    text-overflow: ellipsis !important;
    /* max-width: 135px !important; */
    overflow: hidden !important;
    text-wrap: nowrap !important;
    color:  /* var(--Secondaryonlight, rgba(128, 128, 128)) */ var(--neutrals-text-normal, #484848);
    
}

.Secondary:hover {
    background: var(--neutrals-gray-hover, rgba(128, 128, 128, 0.12)) !important;
}


.Secondary:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) !important;
}




.isRequired {
    color: var(--constants-red, #D01400);
    text-align: center;
    font-family: "sf_pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    padding: 1px 0px;
    align-items: center;
    grid-gap: 3px;
    gap: 0;
    border-radius: 100px;
    margin-inline-start: -3px;
}


.SecondaryMedium {
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 9px 16px;
    color: var(--neutrals-text-dark, #242424) !important;
    position: relative;

}

.SecondaryMedium:hover {
    background: var(--neutrals-gray-hover, rgba(128, 128, 128, 0.24)) !important;
}

.SecondaryMedium:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) !important;
}


.SecondarySmallicon {
    padding-top: 6px;
    padding-inline-end: 10px;
    padding-inline-start: 8px;
    padding-bottom: 6px;
}

.SecondaryMediumicon {
    padding-top: 8px;
    padding-inline-end: 8px;
    padding-inline-start: 8px;
    padding-bottom: 8px;
}

.SecondaryisActive {
    color: var(--primary-on-light, #1080FF);
    background: var(--primary-ultra-light, rgba(16, 128, 255, 0.10));
}

.SecondaryisActive  span{
    color: var(--neutrals-text-dark, black);
}

.SecondarySmallisActive:hover {
    color: var(--neutrals-text-dark, black);
    background: var(--primary-active, rgba(16, 128, 255, 0.24)) !important;
}

.SecondarySmallisActive:hover  span{
    color: var(--neutrals-text-dark, black);
}


.SecondaryMediumisActive:hover {
    color: var(--neutrals-text-dark, #242424);
    background: var(--primary-active, rgba(16, 128, 255, 0.24)) !important
}


.circleBadge {
    background-color: #EC1700;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    inset-inline-end: 6px;
    top: 6px;
}


.Secondarydisabled {
    opacity: 0.6;
    cursor: not-allowed;
}


.SecondarySmall {
    padding: 6px;
}

.SecondarySmall img{
    width: 14px;
    height: 14px;
}








.SecondaryLarge {
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 9px 16px;
    color: var(--neutrals-text-dark, #242424) !important;
    position: relative;

}

.SecondaryLarge:hover {
    background: var(--neutrals-gray-hover, rgba(128, 128, 128, 0.24)) !important;
}

.SecondaryLarge:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) !important;
}

.SecondaryLargeicon {
    padding-top: 13px;
    padding-inline-end: 8px;
    padding-inline-start: 8px;
    padding-bottom: 13px;
}



.SecondaryLargeisActive:hover {
    color: var(--neutrals-text-dark, #242424);
    background: var(--primary-active, rgba(16, 128, 255, 0.24)) !important
}
