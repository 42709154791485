.Small {
    height: 16px;
    width: 16px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.unchecked {
    height: calc(16px - 1.6px);
    width: calc(16px - 1.6px);
    border: .8px solid #D5D5D5;
}

.checked {
    height: 16px;
    width: 16px;
    background-color: #1080FF;

}


.indeterminate {
    height: 16px;
    width: 16px;
    background-color: #1080FF;
}

.disabled {
    opacity: .5;
}