.orderTypeContainer {
  width: auto;
  height: 33px;
  border-radius: 8px;
  background-color: #8080800F;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px 4px;
  float: inherit;
  margin-top: 11px;
  margin-inline-start: 20px;
}

.orderType {
  height: 25px;
  padding: 0px 16px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}


.orderTypeActive {
  height: 25px;
  padding: 0px 16px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}


.button {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  background-color: rgba(128, 128, 128, 0.06);
  float: right;
  cursor: pointer;
  margin-top: 13px;
  gap: 8px;
}


.button img {
  width: 16px;
  height: 16px;
}


.buttonText{
  margin: 0;
  padding: 0;
}




.buttonx {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  float: right;
  cursor: pointer;
  margin-top: 13px;
  gap: 8px;
  background: linear-gradient(180deg, #25B7FF 0%, #1080FF 100%);
  margin-inline-start: 12px;
}


.buttonx img {
  width: 16px;
  height: 16px;
}


.buttonxText{
  margin: 0;
  padding: 0;
}

.HeaderContainer {
  width: 100%;
  height: 56px;
  margin: 0px;
  padding: 0;
  background-color: #ffffff;
  float: left;
  z-index: 6;
  top: 0;
  border-bottom: 1px solid #8080800F;

}

.HeaderMenu {
  width: 16px;
  height: 16px;
  margin: 1px 0 0;
  padding: 0;
  float: left;
  margin: 13px 0 0 13px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  background-color: #8080800F;
}

.HeaderLogoType {
  width: auto;
  height: 40px;
  margin: 8px 0px 0px 16px;
  float: left;
  cursor: pointer;
}


.ProfileMenuIcon {
  float: right;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 4px 0 rgba(128, 128, 128, 0.3);
  border: solid 2.1px #ffffff;
  background-color: #ffffff;
  margin: 10px 16px 0 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}

.HeaderContainerMobile {
  height: 113px;
}

.orderTypeContainerMobile{
  margin: 0px 15px ;
  width: calc(100% - 30px);
  padding: 0px 0px;
}

.rowContainer {
  width: 100%;
  border-top: 1px solid rgba(244, 244, 247, 1);
  padding-top: 10px;
  float: left;
  border-bottom: 1px solid rgba(244, 244, 247, 1);
  padding-bottom: 10px;
  margin-top: 10px
}


.HeaderContainerMobileOneOrderType {
  height: 60px;
}