.input{
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 4px;
  justify-content: start;
  width: 100%;
}


.inputContainer{
    height: 32px;
    border-radius: 8px;
    border: 1px solid #F4F4F7;
    background: #FFF;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    justify-content: start;
    width: calc(100% - 16px);
    padding: 0 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
  }
  

  
.inputContainer input {
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    color: var(--neutrals-text-dark, #242424);
    outline: none;
    border: unset;
    flex: 1;
    width: inherit;
  }

  .inputContainer input::placeholder {
    color: var(--neutrals-text-light, #808080);

  }
  
  .inputContainer input::-ms-input-placeholder { 
    color: var(--neutrals-text-light, #808080);

  }


  .focus {
    border: 1px solid var(--primary-active, rgba(16, 128, 255, 0.24));
  }

  .isRequired {
    color: var(--constants-red, #D01400);
    text-align: center;
    /* font-family: "sf_pro"; */
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    padding: 1px 4px;
    align-items: center;
    gap: 3px;
    border-radius: 100px;
    /* background: var(--constants-red-ultra-light, rgba(208, 20, 0, 0.08)); */
}

.error{
  color: var(--Constants-Red, #D01400);
/* Body/Tiny/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-inline-start: 6px;
}

.errorBorder{
  border: 1px solid var(--Constants-Red, #D01400) !important;
}

.Large {
  height: 42px;
}