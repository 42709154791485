.PickupOnlyNewOrderContainer {
  background: #FFF;
  display: flex;
  width: calc(100% - 32px);
  padding: 16px 16px 13px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 14px;
  gap: 14px;
  height: auto;
  max-height: 89px;
}


.PickupOnlyNewOrderContainerFirstRow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  gap:6px;
  align-items: center;
  justify-content: start;
  float: inline-start;
  width: 100%;
}

.PickupOnlyNewOrderContainerSecondRow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
  float: inline-start;
  width: 100%;
}

.PickupOnlyNewOrderSelectedContainer {
  background: #FFF;
  display: flex;
  width: calc(100% - 32px);
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 14px;
  gap:8px;
  margin-top: 6px;
}


.PickupOnlyNewOrderSelectedContainerFrow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  float: inline-start;
  width: 100%;
  justify-content: flex-start;
}

.hr{
  border: unset;
  border-top: 1px solid #F4F4F7;
  border-top-color: #F4F4F7;
  height: 1px;
  width: 431px;
  margin: 0;
  margin-top: -16px;
}

.hr2 {
  border: unset;
  border-top: 1px solid #F4F4F7;
  border-top-color: #F4F4F7;
  height: 1px;
  width: 431px;
  margin: 0;
  margin-top: -16px;
  margin-bottom: 8px;
}

.PickupDelivery{
  margin-top: 6px;
}


.dropNumber {
  min-width: 14px;
  height: 14px;
  border-radius: 4px;
  background: rgba(72, 72, 72, 0.20);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(72, 72, 72, 0.80);

  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
}


.starRequired {
  color: #D01400;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  margin-inline-start: -5px;
}