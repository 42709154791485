.TableFilter {
  width: calc(100% - 30px);
  padding: 10px 15px;
  min-height: auto;
  /* border-top: 1px solid #8080800F; */
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  flex-wrap: wrap;
}


.divider {
  border-right: 1px solid #F4F4F7;
  width: 1px;
  height: 32px;
}

.DropDown{
  padding-inline-start: 8px
}