.Small {
    display: inline-flex;
    padding: 6px 8px 6px 8px;
    padding-inline-start: 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));
    height: 13px;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--neutrals-text-normal, #484848);
    user-select: none;
    position: relative;
    height: 14px;
    text-wrap: nowrap;
}

.Small img{
    width: 14px;
    height: 14px;
}




.Small:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) ;
    color: var(--neutrals-text-normal, #484848) ;

}

.Small:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) ;
    color: var(--neutrals-text-normal, #484848) ;

}

.Small:hover {
    background: var(--neutrals-gray-hover, rgba(128, 128, 128, 0.12)) ;
    color: var(--neutrals-text-normal, #484848) ;
}

.Medium {
    display: inline-flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));
    height: 16px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--neutrals-text-dark, #242424);
    user-select: none;
    position: relative;
    text-wrap: nowrap;
}

.Medium:hover {
    background: var(--neutrals-gray-hover, rgba(128, 128, 128, 0.12)) ;
    color: var(--neutrals-text-normal, #484848) ;
}

.Medium:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) ;
    color: var(--neutrals-text-normal, #484848) ;

}


.isRequired {
    color: var(--constants-red, #D01400);
    text-align: center;
    font-family: "sf_pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    padding: 1px 0px;
    align-items: center;
    grid-gap: 3px;
    gap: 0;
    border-radius: 100px;
    margin-inline-start: -3px;
}


.Large {
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: #FFF;
    height: 16px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #484848;
    user-select: none;
    position: relative;
    text-wrap: nowrap;
}


.title {
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    user-select: text;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.Smallselected {
    /* background: var(--primary-ultra-light, rgba(16, 128, 255, 0.08));
    color: var(--primary-primary, #1080FF); */

    color: var(--neutrals-text-dark, black);
    background: var(--primary-ultra-light, rgba(16, 128, 255, 0.10)) ;
}

.Smallselected:hover {
    /* background: var(--primary-active,  rgba(16, 128, 255, 0.24)) !important;
    color: var(--primary-primary, #1080FF) !important; */
    background: var(--primary-active,  rgba(16, 128, 255, 0.24)) !important;
    color: var(--neutrals-text-dark, black);

}


.Mediumselected {
    background: var(--primary-ultra-light, rgba(16, 128, 255, 0.08));
    color: var(--primary-primary, #1080FF);

}

.Largeselected {
    background: var(--primary-ultra-light, rgba(16, 128, 255, 0.08));
    color: var(--primary-primary, #1080FF);

}













.dropdownBox {
    z-index: 5000;
    position: fixed;
    /* min-width: 220px; */
    /* right: 0;
    left: 0; */
    /* margin-top: 2px; */
    width: max-content;

    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 12px 0px rgba(128, 128, 128, 0.20);

    display: flex;
   /* padding: 8px 16px; */
    flex-direction: column;
    align-items: center;
    gap: 8px;
    max-height: unset;
    overflow-y: auto;
    /* min-width: calc(100% - 32px); */
    justify-content: start;
    padding: 8px 16px;
    cursor: pointer;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.dropdownBox::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* .dropdownBox div {
    padding: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--neutrals-text-normal, #484848);
    text-wrap: nowrap;
}

.dropdownBox div img{
    width: 16px;
    height: 16px;

} */

/* .bottomEnd {
    top: calc(100% + 2px);
    inset-inline-end: 0px;
}

.bottomStart {
    top: calc(100% + 2px);
    inset-inline-start: 0px;
}


.topEnd {
    bottom: calc(100% + 2px);
    inset-inline-end: 0px;

}

.topStart {
    bottom: calc(100% + 2px);
    inset-inline-start: 0px;

} */

/* .over {
    top: 0px;
    inset-inline-start: 0px;
} */
.selectedDropItem {
    color: #484848 !important;
    font-size: 14px  !important;
    font-style: normal  !important;
    font-weight: 700  !important;
    line-height: normal  !important;

}

.itemsConatiner {

    width: 100%;

    border-radius: 8px;
    display: flex;
    padding:0;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    z-index: 10;
    max-height: 222px;
    overflow-y: auto;
    min-width: calc(100% - 32px);
}

.itemsConatiner div {
    padding: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--neutrals-text-normal, #484848);
    text-wrap: nowrap;
}

.itemsConatiner div img{
    width: 16px;
    height: 16px;

}