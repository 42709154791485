
.AddressbookNewOrderConatiner{
  height: 100%;
    width: 330px;
    position: fixed;
    z-index: 6;
    top: 0px;
    background: #F4F4F7;
    padding: 0px;
    margin-inline-start: 464px;
    padding-inline-start: 1px;
}

@media(max-width:599px) {

  .AddressbookNewOrderConatiner{
    margin: 0px;
    left: 0px;
    z-index: 8;
    width: 100%;
  }
}


.largeHeader{
  border-radius: 0;
  background: #FFF;
  width: calc(329px - 32px);
  height: auto;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

@media(max-width:599px) {

  .largeHeader{
    width: 100%;
  }
}

.largeHeaderTitles{
  height: 32px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: auto;
  align-items: start;
  justify-content: center;
}

.NewOrderAddressbookSearchbarContainer{
  width: calc(100% - 33px);
  height: auto;
  padding: 16px;
  background-color: white;
  border-top: 1px solid #F4F4F7;
}

.AddressbookMenuListContainer{
  width: calc(100% - 32px);
  left: 16px;
  top: 107px;
  height: calc(100% - 107px);
}

.AddressbookMenuListContainer {
  width: calc(100% - 0px);
  height: calc(100% - 130px);
  position: absolute;
  left: 0px;
  top: 123px;
  bottom: 0px;
  overflow-y: scroll;
}

.addressItemMenuContainer {
  width: calc(100% - 34px);
  height: auto;
  padding: 12px 16px;
  border-radius: 0px;
  background-color: white;
  float: left;
  margin-bottom: 6px;
  cursor: pointer;
  margin-inline-start: 1px;
  margin-inline-end: 1px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.frow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 6px;
}

.rowTexts{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;
  flex: 1;
}

.innerFrow {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
    flex: 1;
}