.PickupOnlyNewOrderContainer {
  background: #FFF;
  display: flex;
  width: calc(100% - 32px);
  padding: 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 14px;
  gap: 14px;
  height: auto;
  max-height: 89px;
}


.PickupOnlyNewOrderContainerFirstRow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  gap:6px;
  align-items: center;
  justify-content: start;
  float: inline-start;
  width: 100%;
}

.PickupOnlyNewOrderContainerSecondRow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
  float: inline-start;
  width: 100%;
}

.PickupOnlyNewOrderSelectedContainer {
  background: #FFF;
  display: flex;
  width: calc(100% - 32px);
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 14px;
  gap:8px;
}


.PickupOnlyNewOrderSelectedContainerFrow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  float: inline-start;
  width: 100%;
  justify-content: flex-start;
}

.starRequired {
  color: #D01400;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  margin-inline-start: -5px;
}

@media(max-width:599px) {
  .NewCustomerRegisterContainer{
    /* position: absolute;
    z-index: 900;
    left: 0px;
    margin: 0px;
    width: 100vw;
    height: 100vh; */

    height: auto;
    width: 100%;
  }
   

  .largeHeader{
    width: calc(100vw - 32px);
  }

  .body {
    width: calc(100vw - 32px);

  }
}
