.bodyContainer {
  width: 100%;
  height: auto;
}

.firstRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.P_Hub_D {
  border-inline-end: 2px solid #F4F4F7;
  height: 20px;
  padding-inline-end: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.groupStatus {
  flex: 1;
  margin-inline-start: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.groupStatusdiv {
  height: 24px;
  padding: 0 8px 0 12px ;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: default;
  margin: 0px;
}

.groupStatusspan {
  background-color: white;
  height: 16px;
  border-radius: 8px;
  padding: 0px 4px ;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 8px;
  color: rgba(36, 36, 36, 1) !important;
}


.groupStatusdiv .groupStatusspan span{
  color: rgba(36, 36, 36, 1) !important;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #8080800F;
  margin-top: 10px;
  margin-bottom: 10px;
}

.DropDown {
  width: calc(100% - 20px);
}

.RouteDropDown {
  flex:1;
}




.itemsConatiner {

  width: 100%;

  border-radius: 8px;
  display: flex;
  padding:0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 10;
  max-height: 197px;
  overflow-y: auto;
  min-width: calc(100% - 32px);
}

.itemsConatiner div {
  padding: 8px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--neutrals-text-normal, #484848);
  text-wrap: nowrap;
}

.itemsConatiner div img{
  width: 16px;
  height: 16px;

}

.itemName {
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemDriverName {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.error {
  margin-top: 12px;
}