.SearchList{
    flex: 1;
    width: calc(100% + 16px);
    margin-inline-start: -8px;
    margin-top: -6px;
    height: auto;
    border-radius: 8px;
    background: transparent;
    transition: 50ms all ease-in-out;
}


.SearchListActive{
    height: 211px;
    max-height: 211px;
    box-shadow: -1px 0px 8px 0px rgba(128, 128, 128, 0.16);
    border-radius: 8px;
    background: #FFF;
    z-index: 30 !important;
}


.SearchListInput{
    display: flex;
    width: calc(100% - 36px);
    height: 22px;
    margin: 6px 8px 4px;
    padding: 4px 10px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid var(--neutrals-blue-gray, #F4F4F7);
}

.SearchListInput input {
    color: var(--neutrals-text-dark, #242424);
    /* Body/Small/Regular */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    border: unset;
    outline: unset;
    flex: 1;
    width: inherit;
}


.SearchListInput input::placeholder {
    color: var(--neutrals-text-light, #808080);

  }
  
  .SearchListInput input::-ms-input-placeholder { 
    color: var(--neutrals-text-light, #808080);

  }



.loadingBody{
    height: 168px;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.listItemCBody{
    height: calc(100% - 32px);
    width: 100%;
    overflow-y: scroll;
}

.inputInnerChildren{
    padding-inline-end: 3px;
    width: calc(100% - 31px);
}

.focus {
    border: 1px solid var(--primary-active, rgba(16, 128, 255, 0.24));
  }