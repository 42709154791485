.size10 svg[width]{
    width: 10px;
    height: 10px;
}
.size12 svg[width]{
    width: 12px;
    height: 12px;
}
.size12 svg[width]{
    width: 14px;
    height: 14px;
}

.size16 svg[width]{
    width: 16px;
    height: 16px;
}

.size24 svg[width]{
    width: 24px;
    height: 24px;
}

.size32 svg[width]{
    width: 32px;
    height: 32px;
}

.size56 svg[width]{
    width: 56px;
    height: 56px;
}

.fucking {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.fucking path[opacity="0.99"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 1;
  }


.fucking path[opacity="0.7"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 1;
  }


  .fucking path[opacity="0.8"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 1;
  }



  .fucking path[opacity="0.7"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 1;
  }

  .fucking path[fill-opacity="0.7"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 1;
  }

  .fucking path[opacity="0.8"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 0.99;
  }

  .fucking path[fill-opacity="0.8"][fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
    opacity: 0.99;
  }




  .fucking path[fill-opacity="0.2"][fill="#1080FF"] {
    fill: var(--icon_light_start, #1080FF);
    fill-opacity: 1;

  }

  .fucking path[opacity="0.2"][fill="#1080FF"] {
    fill: var(--icon_light_start, #1080FF);
    opacity:1;
  }

  .fucking path[fill-opacity="0.4"][fill="#1080FF"] {
    fill: var(--icon_light_start, #1080FF);
    fill-opacity: 1;
  }

  .fucking path[opacity="0.4"][fill="#1080FF"] {
    fill: var(--icon_light_start, #1080FF);
    opacity: 1;
  }

  .fucking path[stroke="#1080FF"] {
    stroke: var(--icon_dark_start, #1080FF);
  }

  .fucking path[fill="#1080FF"] {
    fill: var(--icon_dark_start, #1080FF);
  }



  .fucking stop[stop-color="#0084E4"] {
    stop-color: var(--icon_dark_start, #1080FF);
  }



  .fucking stop[stop-color="#004CC4"] {
    stop-color: var(--icon_dark_start, #1080FF);
  }

  .fucking stop[stop-color="#BDE9FF"] {
    stop-color: var(--icon_light_start, #1080FF);
  }



  .fucking stop[stop-color="#B7D9FF"] {
    stop-color: var(--icon_light_start, #1080FF);
  }
