.Small {
    height: 14px;
    padding: 1px 4px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    gap: 3px;
}


.Medium {
    height: 16px;
    padding: 2px 6px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    gap: 3px;
}


.Large {
    height: 17px;
    padding: 4px 8px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-weight: 500;
    font-size: 14px;
}