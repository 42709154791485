.Container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 300px;
    height: auto;
    flex: 1;
    margin-bottom: 102px;justify-content: center;
    margin-top: 36px;
}

.Switch{
  width: -webkit-fill-available;
  margin-top: 24px;
  margin-bottom: 24px;
}


.Switch span{
  flex:1;
}

.google {
  width: -webkit-fill-available;
  margin-bottom: 16px;
}

.email {
  width: -webkit-fill-available;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #F1F1F1;
  margin-top: 24px;
  display: flex
;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.hr span{
  color: rgb(0, 0, 0);
  background-color: white;
  padding: 15px;
}


.registerLink {
    display: flex;
    flex-direction: row;
    gap: 1px;
    margin-top: 24px;
    cursor: pointer;
    align-self: flex-start;
}


.appleFacebookContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
}


.appleFacebookContainer div{
  flex: 1;
}


.phoneTitleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: 24px;
  gap: 4px;
}

.phoneContainer {
  width: calc(100% - 20px);
  height: 42px;
  border-radius: 8px;
  border: 1px solid #F4F4F7;
  padding: 0 9px;
}


.phoneContainer {
  width: calc(100% - 20px);
  height: 42px;
  border-radius: 8px;
  border: 1px solid #F4F4F7;
  padding: 0 9px;
}



.loginpersonPhoneInput {
  margin-top: 12px;
}


.loginpersonPhoneInput * {
  padding: 0px !important;
}


.loginpersonPhoneInput input {
  border: none;
   font-family: "SFProRounded";
  font-size: 14px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  outline: none;
  background-color: transparent;
  margin-left: 10px;
}


.loginpersonPhoneInput div div {
  width: 16px;
  box-shadow: none;
  background-color: transparent;
}

.phoneContainer_error {
  border-color: red;
}

.mrgbg {
  margin-top: 16px;
}