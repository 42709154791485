

.button {
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0 8px;
  border-radius: 8px;
  background-color: #8080800A;
  float: right;
  cursor: pointer;
  gap: 8px;
  border: 1px solid rgba(128, 128, 128, 0.2);
}


.button img {
  width: 16px;
  height: 16px;
}


.buttonText{
  margin: 0;
  padding: 0;
}


.headerButtonsContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  float: inline-end;
  gap: 12px;
  margin-top: 20px;
}

.statusContainer {
  width: calc(100% - 65px);
  height: auto;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgb(128 128 128 / 16%);
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
}

.horizLine {
  width: 100%;
  border: 1px solid rgba(88, 167, 255, 1);
  opacity: 10%;
}


.statusRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.eventDetailsContainer {
  width: calc(100% - 40px);
  height: 33px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgb(128 128 128 / 16%);
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 10px;
  padding: 4px;
}

.eventDetailsContainer span {
  height: 33px;
  width: auto;
  background-color: transparent;
  flex: 1;
  color: #484848;
  font-family:  SFProRounded;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: right;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}

.eventDetailsContainer .activeTab {
  background-color: #1080FF1A;
  color: #1080FF;
}

.pickup_dropOff_collapseRowContainer {
  width: 480px;
  height: 74px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pickup_dropOff_collapseRow {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}


.pickup_dropOff_collapseSubRow {
  width: calc(calc(100% - 20px) / 3);
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;
}


.noteContainer {
  width: calc(100% - 40px);
  height: 44px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgb(128 128 128 / 16%);
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  gap: 8px;
  padding: 0px 16px;
}

.noteContainer img {
  width: 20px;
}
.noteContainer span {
  font-family: "SFProRounded";
  font-size: 14px;
  font-weight: 300;
  line-height: 16.71px;
  text-align: left;
  color: #242424;  
}