
.button{
    color: var(--neutrals-text-normal, #484848);
    text-align: center;
    /* font-family: "sf_pro"; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06));
    display: inline-flex;
    padding: 6px 12px 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    user-select: none;
    max-height: 14px;
    position: relative;


}

.buttonspan {
    text-overflow: ellipsis !important;
    max-width: 135px !important;
    overflow: hidden !important;
    text-wrap: nowrap !important;
}

.button:hover {
    background: var(--neutrals-gray-hover, rgba(128, 128, 128, 0.12)) !important;
}

.button:active {
    background: var(--neutrals-gray-light, rgba(128, 128, 128, 0.20)) !important;
}

.isRequired {
    color: var(--constants-red, #D01400);
    text-align: center;
    /* font-family: "sf_pro"; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 1px 4px;
    align-items: center;
    gap: 3px;
    border-radius: 100px;
    background: var(--constants-red-ultra-light, rgba(208, 20, 0, 0.08));
}

.buttonSmallicon {
    padding-top: 6px;
    padding-inline-end: 10px;
    padding-inline-start: 8px;
    padding-bottom: 6px;
}

.buttonisActive {
    color: var(--primary-primary, #1080FF);
    background: var(--primary-ultra-light, rgba(16, 128, 255, 0.08));
}

.buttonSmallisActive:hover {
    color: var(--primary-primary, #1080FF);
    background: var(--primary-active, rgba(16, 128, 255, 0.24)) !important;
}

.buttondisabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.buttonNOhOVER:active {
    color: var(--neutrals-text-normal, #484848) !important;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06)) !important;
}

.buttonNOhOVER:hover {
    color: var(--neutrals-text-normal, #484848) !important;
    background: var(--neutrals-gray-ultra-light, rgba(128, 128, 128, 0.06)) !important;
}

.dropdownBox {
    position: absolute;
    width: 210px;

    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 12px 0px rgba(128, 128, 128, 0.2);

    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    z-index: 10;
    overflow-y: auto;
    justify-content: start;
    cursor: default;

}


.bottomEnd {
    top: calc(100% + 4px);
    inset-inline-end: 0px;
}

.bottomStart {
    top: calc(100% + 4px);
    inset-inline-start: 0px;
}


.topEnd {
    bottom: calc(100% + 4px);
    inset-inline-end: 0px;

}

.topStart {
    bottom: calc(100% + 4px);
    inset-inline-start: 0px;

}


.drop{
    width: calc(100% - 20px);
}


.itemsConatiner {

    width: 100%;
  
    border-radius: 8px;
    display: flex;
    padding:0;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    z-index: 10;
    max-height: 80px;
    overflow-y: auto;
    min-width: calc(100% - 32px);
  }
  
  .itemsConatiner div {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-wrap: nowrap;
    cursor: pointer;
  }


  .footer{
    height: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}


.Error {
    width: 100%;
}


.IsbetweenContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.dropItem {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 6px;
}

.drop{
    width: calc(100% - 20px);
}


.Activebutton {
    background-color: var(--filterActivebutton , rgba(16, 128, 255, 0.08)) !important; 
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    white-space: pre;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
}