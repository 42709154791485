.NewCustomerRegisterContainer{
  height: auto;
  width: 100%;
  /* position: fixed; */
  /* z-index: 8; */
  /* top: 0px;
  padding: 0px;
  margin-inline-start: 464px;
  padding-inline-start: 1px;
  overflow-y: auto; */
}


.largeHeader{
  border-radius: 0;
  background: #FFF;
  width: 100%;
  height: auto;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.largeHeaderTitles{
  height: 32px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: auto;
  align-items: start;
  justify-content: center;
}


.SmallTitle{
  color: var(--neutrals-text-dark, #242424);
  /* Body/Middle/Bold */
  padding: 0;
  margin: 0;
  margin-top: 20px;
  display: block;
  width: 100%;
}


.body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  float: left;
  background: #FFF;
  margin-top: 12px;
}


.inputContainer{
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-blue-gray, #F4F4F7);
  background: #FFF;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  justify-content: start;
  width: calc(100% - 16px);
  padding: 0 8px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-top: 0px !important;
}


.inputContainer input {
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: var(--neutrals-text-dark, #242424) !important;
}



.input {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--neutrals-text-light, #808080);
  border: unset;
  outline: unset;
  background-color: transparent;
  flex: 1;

}


.inputsConatiner {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}


.threeCOLUMNS {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 13px;
  padding: 0;
}

.twoCOLUMNS {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 13px;
  padding: 0;
}

.threeCOLUMNS div {
  /* width: calc(calc(100% - 26px) / 3); */
}


.PhoneInput {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  position: relative;
}

.PhoneInputRequired {
  position: absolute;
  float: inline-end;
  margin-inline-end: 6px;
  background-color: transparent !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  margin: 0 !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-top: 10px !important;
  padding-inline-end: 11px !important;
}



.addAddressbookContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}


.addAddressbookinnerContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}


.addAddressnamephoneContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 6px;
  width: 100%;
}

.addAddressnamephoneInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
}


.collapseHeaderContainer {
  width: 100%;
  height: 33px;
  border-radius: 8px;
  background-color: rgba(128, 128, 128, 0.06);

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;

  user-select: none;
}

.flexInput {
  flex: 1;
}


.PhoneInputxc {
  width: 278px !important;
  height: 32px;
  padding-left: 8px;
  float: left;
  border: none;
  outline: none;
  text-align: left;
  font-family: sf_pro;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  border-radius: 8px;
  background-color: white !important;
  flex-direction: row-reverse;
  border-radius: 8px;
  border: 1px solid #F4F4F7;
  background-color: rgba(128, 128, 128, 0.04);
  width: 100% !important;
}

@media(max-width:599px) {
  .NewCustomerRegisterContainer{
    /* position: absolute;
    z-index: 900;
    left: 0px;
    margin: 0px;
    width: 100vw;
    height: 100vh; */
    height: auto;
  width: 100%;
  }
   

  .largeHeader{
    width: calc(100vw - 32px);
  }

  .body {
    width: calc(100vw - 32px);

  }
}
