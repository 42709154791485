.footerbtnCreate {
  margin-top: 15px;
  margin-inline-end: 16px;
  float: inline-end;

    display: flex;
    /* padding: 8px 10px; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1px;
    border-radius: 8px;
    height: 32px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    text-wrap: nowrap;
    color: var(--primary-text-color, white);
    overflow: hidden;
}

.footerbtnCreate div {
  background: var(--primary-primary, rgba(128, 128, 128, 0.12));
  padding: 8px 10px;
  height: 16px !important;

}


.footerbtnCreate:hover div {
  background: var(--primary-dark, #1080FF);
}




.dropdown-animation-enter {
  opacity: 0;
  transform: translateY(10px);
}

.dropdown-animation-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms, transform 300ms;
}

.dropdown-animation-exit {
  opacity: 1;
  transform: translateY(0px);
}

.dropdown-animation-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms, transform 300ms;
}