.modal {
    position: fixed;
    /* Stay in place */
    z-index: 50;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Black w/ opacity */
}





.back {
    z-index:52 ;
}

.backDrop{
    position: fixed;
    /* Stay in place */
    z-index: 51;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
}