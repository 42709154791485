.status {
    width: 100%;
    float: left;
    direction: var(--main-direction);
    height: auto;
    margin: 0px 0 6px;
    background-color: #fff;
}


.statusLogo{
    width: 14px;
    height: 14px;
    float: var(--main-defaultFloat);
    margin-top: 17px;
    margin-inline-start: 16px;
    margin-bottom: 15px;
}
.statusText {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #004cc4;
    float: var(--main-defaultFloat);
    margin-top: 15.5px;
    margin-inline-start: 16px;
    margin-bottom: 15px;

}

.statusAt{
    float: var(--main-defaultFloat);
    margin-top: 16px;
    margin-inline-start: 6px;
    padding: 2px 6px;
    border-radius: 100px;
    background-color: rgba(128, 128, 128, 0.06);
    text-align: left;
    color: #484848;
    font-size: 13px;

}

.statusAt img {
    width: 14px;
    height: 14px;
    float: var(--main-defaultFloat);
    margin-top: 0px;
    margin-inline-end: 3px;
}

.orderCode {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #484848;
    float: var(--main-inverseDefaultFloat);
    margin-top: 18px;
    margin-inline-end: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5.5px;
    cursor: pointer;
}

.orderCodeTToolTipContainer {
    width: auto;
    height: auto;
    top: 96px;
    left: 352px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: white;
    border-radius: 8px;
}


.orderCodeTToolTipContainer span{
    display: flex;
    flex-direction: row;
    gap: 0;
    background-color: white;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    height: 33px;
    align-items: center;
}


.orderCodeTToolTipContainer span img{
  margin-inline-end: 8px;
}

.hr {
    background-color: #f4f4f7;
    height: 1px;
    width: 431px;
    float: var(--main-defaultFloat);
    margin: 0;
    margin-inline-start: 16px;
    margin-inline-end: 16px;
    border: none;
}

.star {
    margin-top: 15px;
    margin-inline-start: 16px;
    float: var(--main-defaultFloat);
    margin-bottom: 14px;
}

.rateText {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #484848;
    float: var(--main-defaultFloat);
    width: calc(100% - 45px);
    margin-inline-start: 8px;
    margin-top: 14px;

}

.dipatchRowContainer {
    width: calc(100% - 20px);
    float: left;
    margin-inline-start: 16px;
    height: 32px;
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dipatchRowDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.dipatchRowDivR{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.dipatchType {
    font-size: 14px;
    font-weight: 500;
    color: #484848;
    margin-inline-start: 4.9px;
}



.driverStatus{
    float: var(--main-defaultFloat);
    margin-inline-start: 16px;
    padding: 4px 8px;
    text-align: left;
    color: #484848;
    font-size: 13px;
    border-radius: 100px;
    background-color: rgba(128, 128, 128, 0.06);
    margin-inline-end: auto;


}

.driverStatus img {
    width: 14px;
    height: 14px;
    float: var(--main-defaultFloat);
    margin-top: 1px;
    margin-inline-end: 6px;
}



.urgent{
    width: 16px;
    height: 16px;
    padding: 7px;
    border-radius: 8px;
    margin-inline-end: 12px;
    float: var(--main-inverseDefaultFloat);
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.06);
}

.isUrgent {
    background-color: rgba(16, 128, 255, 0.08);
}

.marginFleft {
    /* margin-inline-start: auto; */
}



.profsContainer {
    width: calc(100% - 32px);
    height: auto;
    float: var(--main-defaultFloat);
    margin-inline-start: 16px;
    gap: 16px 13.5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.profsItem {
    width: 90px;
    height: 90px;
    border-radius: 5.14px;
    background-color: #8080800F;
}


.innerProofItems {
    width: calc(100% - 20px);
    float: left;
    margin-left: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}