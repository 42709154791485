
.invoiceItemContainer {
  width: calc(100% - 40px);
  height: 64px;
  margin: 3px 20px 13px 20px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(128, 128, 128, 0.16);
  background-color: #fff;
  position: relative;
  display: flex;
}

.invoiceItemImg {
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
  background-color: rgba(16, 128, 255, 0.1);
  margin-top: 16px;
  margin-inline-start: 12px;
  margin-inline-end: 0px;
  float: inherit;
}

.invoiceItemNo {
  width: calc(100% - 110px);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  direction: inherit;
  color: #242424;
  float: inherit;
  margin-top: 12px;
  margin-inline-start: 12px;
  margin-inline-end: 0px;
}


.invoiceItemDate {
  width: calc(100% - 110px);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  direction: inherit;
  color: #484848;
  float: inherit;
  position: absolute;
  margin-top: 35px;
  margin-inline-start: 55px;
  margin-inline-end: 0px;
}

.icDownload {
  width: 28px;
  height: auto;
  float: right;
  margin-top: 0px;
  margin-inline-start: 0;
  margin-inline-end: 17.6px;
  cursor: pointer;
}
