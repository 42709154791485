.CustomerListItemContainer{
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: calc(100% - 14px);
  margin: 8px  7px;
  border-bottom: 1px solid #EEE;
  padding: 2.5px 0 9.5px 2px;
  gap: 6px;
  cursor: pointer;
}


.namePhoneContainer{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 30px;
  flex: 1;
  display: grid;
}