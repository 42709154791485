.App{
  width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
}


.innerApp {
  padding: 0;
  width: calc(100vw - 150px);
  height: 100vh;
  margin: 0;
  position: relative;
  flex: 1;
}

.FilterOrderListContainer {
  max-width: max-content;
  float: var(--main-defaultFloat);
  height: calc(100% - 52px);
  background-color: white;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: .3s all ease-in-out;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  direction:var(--main-direction);
}

.mapListContainer {
  width: 100%;
  height: calc(100% - 52px);
  direction: rtl;
}

.mainMapContainer {
  /* position: absolute; */
  transition-property: all !important; 
  transition-duration: 300ms !important; 
  transition: all 300ms ease !important;
  float: var(--main-inverseDefaultFloat);

}

.monitoringContainer {
  display: flex;
  flex-direction: column;
  width: 200px;
  position: absolute;
  bottom: 18px;
  z-index: 6;
  background-color: transparent;
  cursor: pointer;
}

.driversList{
  position: absolute;
  /* right:0px; */
  top: 0;
  direction: rtl;
  height: 100vh;
}
