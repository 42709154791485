
.NewOrder {
  width: 464px;
  height: auto;
  float: left;
  /* box-shadow: 0 2px 8px 0 rgba(128, 128, 128, 0.2); */
  background-color: #F4F4F7;

  display: flex;
    flex-direction: column;
    flex: 1;
    float: left;
    overflow-y: auto;
    max-height: calc(100vh - 65px);

}

.NewOrderOuterDiv {
  width: 464px;
  height: 100%;
  float: left;
  /* box-shadow: 0 2px 8px 0 rgba(128, 128, 128, 0.2); */
  background-color: #F4F4F7;
  z-index: 7;
  position: fixed;
  left: 0px;
  bottom: 0px;
  top: 0px;
  overflow-y: hidden;
  position: relative;
}
.footerbtnCreate {
  margin-top: 15px;
  margin-inline-end: 16px;
  float: inline-end;

    display: flex;
    /* padding: 8px 10px; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1px;
    border-radius: 8px;
    height: 32px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    text-wrap: nowrap;
    color: var(--primary-text-color, white);
    overflow: hidden;
}

.footerbtnCreate div {
  background: var(--primary-primary, rgba(128, 128, 128, 0.12));
  padding: 8px 10px;
  height: 16px !important;

}


.footerbtnCreate:hover div {
  background: var(--primary-dark, #1080FF);
}



.PickupOnlyNewOrderContainerFirstRow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  gap:6px;
  align-items: center;
  justify-content: start;
  float: inline-start;
  height: 16.7px;
}


.starRequired {
  color: #D01400;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  margin-inline-start: -5px;
}

.changeMethodContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: inline-start;
  margin-top: 10px;
  margin-inline-start: 16px;
  margin-bottom: 10px;
  gap: 8px;
  cursor: pointer;
  padding: 5px 0px;
}


.changeMethodIconContainer {
  background-color: var(--primary-ultra-light ,#1080FF0F);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.changeMethodIconContainer svg {
  width: 20px;
  height: 20px;
}

.changeMethodTextsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2px;
}

.paymentMethodsPopper {
  display: flex;
  height: auto;
  width: auto;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: none !important;
  border: solid 1px rgba(128, 128, 128, 0.2);
  background-color: #ffffff;
  z-index: 10;
  flex-direction: column;
  gap: 8px;
}


.methodMethodsItem {
  height: 33px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.dwdwdwd {
  border-radius: 8px !important;
  box-shadow: 0px 1px 12px 0px rgba(128, 128, 128, 0.2) !important;
  margin-inline-start: 50px;
}

.TopupColor {
  color: rgba(36, 36, 36, 1);
}

.TopupContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-inline-start: auto;
  gap: 4px;
  cursor: pointer;
}

.methodDisabledMethodsItem {
  cursor: not-allowed;
}


@media(max-width:599px) {

  .NewOrder {
    width: 100vw;
    max-height: calc(100vh - 169px);
  }

  .footerbtnCreate {
    margin-top: 15px;
    margin-inline-end: 16px;
    float: inline-end;
    margin-left: 16px;
    width: 100%;
    align-items: center;
    width: calc(100% - 40px);
  }

  .footerbtnCreate span {
    justify-content: center;
  }

  .changeMethodContainer {

    margin-inline-start: 0px;

  }

  .dwsdiwhdnsw{
    max-height: 33px;
    margin-bottom: 16px;
  }

  .NewOrderOuterDiv {
    width: 100vw;
  }
  
}

