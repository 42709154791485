.size10 svg[width]{
    width: 10px;
    height: 10px;
}
.size12 svg[width]{
    width: 12px;
    height: 12px;
}
.size12 svg[width]{
    width: 14px;
    height: 14px;
}

.size16 svg[width]{
    width: 16px;
    height: 16px;
}

.size24 svg[width]{
    width: 24px;
    height: 24px;
}

.size32 svg[width]{
    width: 32px;
    height: 32px;
}

.size56 svg[width]{
    width: 56px;
    height: 56px;
}

.fucking {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}




  .fucking path[stroke] {
    stroke: var(--primary-text-color, #1080FF);
  }

  .fucking path[fill] {
    fill: var(--primary-text-color, #1080FF);
  }
