.OtherContainer {
    background: #FFF;
    display: flex;
    width: calc(100% - 32px);
    padding: 16px 16px 16px;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 14px;
    grid-gap: 14px;
    gap: 12px;
    height: auto;
    margin-top: 6px;

    flex-wrap: wrap;
    float: inline-start;
}





.PickupOnlyNewOrderContainerFirstRow {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  gap:6px;
  align-items: center;
  justify-content: start;
  float: inline-start;
  width: 100%;
  justify-content: start;
}


.inputContainerclassName {
  width: calc(50% - 8px);
  flex: 1;
}


.TooltipOnro {
  max-width: 251px;
  height: max-content;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 1px 12px 0px rgba(128, 128, 128, 0.2);

}