.NoMaxWidth span{
  max-width: unset !important;
}

.twoColumns{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.Column{
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title{
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}


.tabContainer {
  background-color: rgba(128, 128, 128, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  height: 33px;
  width: calc(100% - 8px);
  padding-inline-start: 4px;
  padding-inline-end: 4px;
  user-select: none;
  cursor: pointer;
}


/* background: var(--primary-primary, #1080FF); */

.tabBTN {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 25px;
  flex: 1;
  color: black;
}

.tabBTN_Active {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 25px;
  flex: 1;
  color: var(--primary-primary, #1080FF);
  font-weight: 700;

}
  .customCalendar {
    box-shadow: none;
  }